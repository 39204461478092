import {Fancybox} from "@fancyapps/ui";

const fetchData = (url, data, loadHandler) =>{
  fetch(url, {
    method: 'POST',
    body: data,
  })
      .then(checkStatus)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        loadHandler(data)
      })
      .catch((error) => {
        //Здесь обработка ошибки
      });
}

const baseSuccessCallback = (event) => {
  event.preventDefault();
  const form = event.target
  const data = new FormData(form);

  fetchData(form.action, data, () => {
    Fancybox.close();
    Fancybox.show([{ src: "#success", type: "inline" }]);
    form.reset();
  });
};

const baseErrorCallback = (event) => {
  event.preventDefault();

};

function checkStatus(response) {
  if (response.ok) {
    return response;
  }

  const {statusText, status} = response;
  throw new Error(`${status} — ${statusText}`);
}

export const callbacks = {
  base: {
    successCallback: baseSuccessCallback,
    errorCallback: baseErrorCallback,
  },
};
