import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';
import {MOBILE} from '../../constants.js';


export const simpleSliders = function (isHideDesctop = false) {
  const rootsSimple = document.querySelectorAll('[data-slider="simple"]');

  if (rootsSimple.length === 0) {
    return;
  }
  rootsSimple.forEach((root) => {
    const slider = root.querySelector('[data-slider="simple-slider"]');
    if (slider) {
      const swiper = new Swiper(slider, {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        autoHeight: true,
        navigation: {
          nextEl: root.querySelector('[data-slider="simple-next"]'),
          prevEl: root.querySelector('[data-slider="simple-prev"]'),
        },
        pagination: {
          el: root.querySelector('[data-slider="simple-pagination"]'),
          clickable: true,
        },
      });

      if (window.innerWidth > MOBILE) {
        swiper.destroy();
      }
    }
  })

}
