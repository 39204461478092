import { Fancybox } from "@fancyapps/ui";

export const initOpenVideo = function () {
  const videoWrappers = document.querySelectorAll('[data-video=root]');
  if (videoWrappers.length === 0) {
    return;
  }

  videoWrappers.forEach((videoWrapper) => {
    const video = videoWrapper.dataset.videoSrc;
    if (!video) {
      return;
    }

    const button = videoWrapper.querySelector('[data-video=button]');
    if (!button) {
      return;
    }

    button.addEventListener('click', (evt) => {
      evt.preventDefault();

      Fancybox.show([
        {
          src: video,
          type: "video",
          ratio: 16 / 10,
          width: 640,
          height: 360,
        },
      ]);
    });
  });
}
