import { clientsSlider } from './clients-slider.js';
import { videoSlider } from './video-slider.js';
import { caseSlider } from './case-slider.js'
import { simpleSliders } from './simple-slider.js';
import { initExperience } from './experience-slider.js';

export const initSlider = function () {
  clientsSlider();
  videoSlider();
  caseSlider();
  simpleSliders(true);
  initExperience();
}
