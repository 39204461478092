export const Addresses = {
  SPB: {
    phone: '+7 (812) 303-87-43',
    phoneLink: 'tel:+78123038743',
    address: 'пр. Медиков, д. 9, лит. <br> Будни: 10:00-18:00',
  },
  MSK: {
    phone: '+7 (499) 380-73-81',
    phoneLink: 'tel:+74993807381',
    address: 'пер. Армянский, д.9, стр.1<br> Будни: 10:00-18:00',
  }
}

export const MOBILE = 767;
