export class MobileMenu {
  constructor() {
    this._ESC_CODE = 27;
    this._menu = null;
    this._isOpen = false;
    this._content = null;
    this._button = null;
    this._activeClass = 'is-active';
    this._documentClickHandler = this._documentClickHandler.bind(this);
    this._documentKeyDownCloseHandler = this._documentKeyDownCloseHandler.bind(this);
    this._buttonClickHandler = this._buttonClickHandler.bind(this);
    this._buttonItemsClickHandler = this._buttonItemsClickHandler.bind(this);
    this._buttonClickCloseMenuHandler = this._buttonClickCloseMenuHandler.bind(this);
    this._init();
  }

  _init() {
    this._menu = document.querySelector('[data-mobile-menu="root"]');
    if (!this._menu) {
      return;
    }

    this._buttons = this._menu.querySelectorAll('[data-mobile-menu="button"]');
    if (this._buttons.length === 0) {
      //выбросить исключение
      return;
    }
    this._buttons.forEach((button) => {
      button.addEventListener('click', this._buttonClickHandler);
    })

    this._closeButtons = this._menu.querySelectorAll('[data-mobile-menu="close"]');
    if (this._closeButtons.length === 0) {
      //выбросить исключение
      return;
    }
    this._closeButtons.forEach((button) => {
      button.addEventListener('click', this._buttonClickCloseMenuHandler);
    })

    this._buttonItemsClickHandler();

    this._content = this._menu.querySelector('[data-mobile-menu="content"]');

    document.addEventListener("keydown", this._documentKeyDownCloseHandler);
  }

  _setIsOpen(isOpen) {
    this._isOpen = isOpen;
    if (isOpen) {
      window.scrollLock.disableScrolling()
    } else {
      window.scrollLock.enableScrolling()
    }
  }

  _buttonClickHandler(evt) {
    evt.preventDefault();
    this._setIsOpen(!this._isOpen);
    this._menu.classList.toggle(this._activeClass);

    if (this._menu.classList.contains(this._activeClass)) {
      if (this._content) {
        setTimeout(() => {
          document.addEventListener('click', this._documentClickHandler);
        }, 0)
      }
    } else {
      document.removeEventListener('click', this._documentClickHandler);
    }
  }

  _buttonClickCloseMenuHandler(evt) {
    evt.preventDefault();
    this._menu.classList.remove(this._activeClass);
    document.removeEventListener('click', this._documentClickHandler);
    this._setIsOpen(false);
  }
  _buttonItemsClickHandler() {
    const items = this._menu.querySelectorAll('[data-mobile-menu="item"]');
    if (items.length === 0) {
      return
    }
    items.forEach((item) => {
      const button = item.querySelector('[data-mobile-menu="item-button"]');
      if (!button) {
        return;
      }
      button.addEventListener('click', (evt) => {
        evt.preventDefault();
        item.classList.toggle(this._activeClass);
      });
    })
  }

  _documentClickHandler(evt) {
    const withinBoundaries = evt.composedPath().includes(this._content);
    if (!withinBoundaries) {
      this._menu.classList.remove(this._activeClass);
      document.removeEventListener('click', this._documentClickHandler);
      this._setIsOpen(false);
    }
  }

  _documentKeyDownCloseHandler(evt) {
    if (evt.keyCode === this._ESC_CODE) {
      if (this._menu.classList.contains(this._activeClass)) {
        evt.preventDefault();
        this._menu.classList.remove(this._activeClass);
        document.removeEventListener('click', this._documentClickHandler);
        this._setIsOpen(false);
      }
    }
  }
}
