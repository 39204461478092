import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';


export const initExperience = function () {
  const experience = document.querySelector('[data-slider="experience"]');
  if(!experience) {
    return;
  }
  const sliderExperience = experience.querySelector('[data-slider="experience-slider"]');
  if (sliderExperience) {
    const swiper = new Swiper(sliderExperience, {
      modules: [Pagination, Navigation],
      slidesPerView: 1,
      spaceBetween: 10,
      loop: false,
      autoHeight: true,
      navigation: {
        nextEl: experience.querySelector('[data-slider="experience-next"]'),
        prevEl: experience.querySelector('[data-slider="experience-prev"]'),
      },
      pagination: {
        el: experience.querySelector('[data-slider="experience-pagination"]'),
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }
    });
  }
}
