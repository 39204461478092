export class ShowMore {
    constructor(block) {
        this._blocks = document.querySelectorAll('[data-more]');
        this._isActive = 'is-open';
    }

    _setButtonAction(block) {
        const button = block.querySelector('[data-more="button"]');
        if (button) {
            button.addEventListener('click', (evt) => {
                evt.preventDefault();
                block.classList.toggle(this._isActive);
            })
        }
    }

    init() {
        if (!this._blocks) {
            return;
        }
        this._blocks.forEach((block) =>  {
            this._setButtonAction(block);
        })

    }

}