import { Fancybox } from "@fancyapps/ui";

export const initFancy = function () {
  Fancybox.bind('[data-fancybox]', {
    on: {
      "init": (fancybox, eventName) => {
        const trigger = fancybox.options.triggerEl;
        if (trigger) {
          const theme = trigger.dataset.button;
          const field = document.querySelector('#callback [name="note"]');
          if (theme && field) {
            field.value = theme;
          }
        }

      },
      "close": (fancybox, eventName) => {
        const form = fancybox.container.querySelector('form');
        if (form) {
          window.form.reset(form);
        }
      },
    },

  });
}
