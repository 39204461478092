import {Addresses} from '../constants.js';

export const initCheckCity = function () {
  const citySelect = document.querySelector('.city-list-wrapper[data-select] select');
  if (!citySelect) {
    return;
  }
  citySelect.addEventListener('change', (evt) => {
    evt.preventDefault();
    const target = evt.target;
    if (!target.value) {
      return;
    }

    const currentAddress = Addresses[target.value.toUpperCase()];

    if (currentAddress !== undefined) {
      const companyInfo = document.querySelectorAll('[data-city]');

      if (companyInfo.length === 0) {
        return;
      }
      companyInfo.forEach((element) => {
        const type = element.dataset.city;
        if (currentAddress[type] !== undefined) {
          element.innerHTML = currentAddress[type];

          if (type === 'phone') {
            element.href = currentAddress['phoneLink'];
            element.innerHTML = currentAddress[type];
          }
        }
      });

    }

  });
}
