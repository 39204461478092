import ScrollLock from './utils/scroll-lock.js';
import { initFancy } from "./modules/fancybox/init-fancybox.js";
import { initMobileMenu } from './modules/mobile-menu/init-mobile-menu.js';
import { initScroll } from "./modules/init-scrollbar.js";
import { ShowMore } from './modules/show-more.js';
import { CustomSelect } from './modules/select/custom-select.js';
import { initPhoneMask } from "./modules/phone-mask/phone-mask.js";
import { Form } from "./modules/form-validate/form.js"
import { initOpenVideo } from "./modules/init-open-video.js";
import { initReviewMore } from "./modules/init-review-more.js";
import { initCheckCity } from "./modules/init-check-city.js";
import { initSlider } from "./modules/slider/init-slider.js";
import { Filters } from "./modules/filters/filters.js";

import { Addresses, MOBILE } from './constants.js';

window.addEventListener('DOMContentLoaded', () => {
  // Select
  const select = new CustomSelect();
  select.init();

  new ShowMore('[data-more="button"]').init();

  //Мобильное меню
  initMobileMenu();
  initCheckCity();
  initScroll();
  initSlider();
  initOpenVideo();
  initFancy();

  new Filters().init();

  initPhoneMask();

  //Валидация форм
  const form = new Form();
  window.form = form;
  form.init();

  initReviewMore();
});
