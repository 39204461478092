export class Filters {
  constructor() {
    //Ключи - код кнопки, значение - кнопка
    this._filter = null;
    this._currentFilter = 1;
    this._isActive = 'is-active';

    this._onClickButtonHandler = this._onClickButtonHandler.bind(this);

  }

  init() {
    this._filter = document.querySelector('[data-filter]');
    if (!this._filter) {
      return;
    }


    const buttons = this._filter.querySelectorAll('[data-filter=button]');

    //Написать исключение
    if (buttons.length === 0) {
      return;
    }

    this._initButtons(buttons);

    this._setBlocks();
  }

  _initButtons(buttons) {
    buttons.forEach((button, idx) => {

      if (!idx) {
        this._toggleFilter(button);
      }

      this._addActionButton(button);
    });
  }

  _clearActiveElements() {
    const elements = this._filter.querySelectorAll(`.${this._isActive}`);

    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.remove(this._isActive);
        element.dataset.sort = 0;
      });
    }
  }

  _setBlocks() {
    const blocks = this._filter.querySelectorAll('[data-filter=block]');
    if (blocks.length === 0) {
      return;
    }
    let sort = 1;
    blocks.forEach((block) => {
      const codes = block.dataset.codes.split(',');
      if (codes.includes(this._currentFilter)) {
        block.classList.add(this._isActive);
        block.dataset.sort = sort;
        sort++;
      }
    });
  }

  _toggleFilter(button) {
    const code = button.dataset.code;
    this._clearActiveElements();

    button.classList.toggle(this._isActive);
    this._currentFilter = code;
    this._setBlocks();
  }

  _addActionButton(button) {
    button.addEventListener('click', this._onClickButtonHandler);
  }

  _onClickButtonHandler(evt) {
    evt.preventDefault();
    const target = evt.target;
    this._toggleFilter(target);
  }
}
