import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export const clientsSlider = function () {
  const root = document.querySelector('[data-slider="clients"]');
  if (!root) {
    return;
  }

  const slider = root.querySelector('[data-slider="clients-slider"]');
  if (!slider) {
    return;
  }

  new Swiper(slider, {
    modules: [Navigation],
    slidesPerView: 2,
    spaceBetween: 0,
    loop: true,
    navigation: {
      nextEl: root.querySelector('[data-slider="clients-next"]'),
      prevEl: root.querySelector('[data-slider="clients-prev"]'),
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 41,
      }
    }
  });
}
