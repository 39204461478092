import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

export const caseSlider = function () {

  const root = document.querySelectorAll('[data-slider="case"]');
  if (!root) {
    return;
  }

  root.forEach(slide => {
    const slider = slide.querySelector('[data-slider="case-slider"]');

    if (!slider) {
      return;
    }

    new Swiper(slider, {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 25,
      loop: false,
      navigation: {
        nextEl: slide.querySelector('[data-slider="case-next"]'),
        prevEl: slide.querySelector('[data-slider="case-prev"]'),
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

      }
    });
  })


}
