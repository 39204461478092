import Scrollbar from '../../../node_modules/smooth-scrollbar/dist/smooth-scrollbar.js';

export const initScroll = function () {
  const texts = document.querySelectorAll('[data-scrollbar]');

  if (texts) {
    texts.forEach(text => {
      Scrollbar.init(text, { alwaysShowTracks: true });
    })
  }
}
