import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export const videoSlider = function () {
  const root = document.querySelector('[data-slider="video"]');
  if (!root) {
    return;
  }

  const slider = root.querySelector('[data-slider="video-slider"]');
  if (!slider) {
    return;
  }

  new Swiper(slider, {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false,
    autoHeight: true,
    navigation: {
      nextEl: root.querySelector('[data-slider="video-next"]'),
      prevEl: root.querySelector('[data-slider="video-prev"]'),
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }
  });
}
