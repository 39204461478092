export const initReviewMore = function () {
  const block = document.querySelector('[data-more-review]');

  if (!block) {
    return;
  }

  const button = block.querySelector('[data-more-review="button"]');

  if (!button) {
    return;
  }

  const elements = block.querySelectorAll('[data-more-review="element"]');

  if (elements.length === 0) {
    return;
  }

  let countActiveElements = 4;

  button.addEventListener('click', (evt) => {
    evt.preventDefault();

    if (countActiveElements >= elements.length - 1) {
      button.style.display = 'none';
    }

    elements.forEach((element, idx) => {
      if (idx > countActiveElements - 1 && idx < countActiveElements + 4) {
        element.classList.add('reviews__item--desctop-active');

      }
    });
    countActiveElements += 4;

  });


}
